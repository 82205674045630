<template>
  <v-container fluid>
    <v-tabs slider-color="primary">
      <v-tab
        :key="tab"
        ripple
        :to="getSubpageRoute(tabKey)"
        v-for="(tab, tabKey) in tabs">
        {{ tab }}
      </v-tab>
    </v-tabs>
    <router-view
      :isSuperAdmin="isSuperAdmin" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tabs: {
        map: 'Liefergebiete',
      },
    }
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
    }),
  },
  methods: {
    getSubpageRoute(pageKey) {
      return {
        name: `tools.${pageKey}`,
      }
    },
  },
}
</script>
